import React from "react";

function Newsletter() {
  return (
    <div className="w-full text-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid lg:grid-cols-2 gap-8">
        <div>
          <h1 className="text-xl md:text-3xl font-bold ">
            Want tips and tricks to optimize your flow?
          </h1>
          <p className="text-gray-500 font-sm">
            Sign up to our newsletter and stay up to date.
          </p>
        </div>
        <div className="mt-6 ">
          <div>
            <input
              type="text"
              placeholder="Enter your email"
              className="outline-none p-2 px-14 rounded-sm text-gray-500 md:px-18"
            />
            <button className="bg-[#00df98] text-white font-medium rounded-sm p-2 px-8 ml-2 md:px-12">
              Join now
            </button>
          </div>
          <p className="text-sm  p-2">
            We care about the protection of your data. Read our
            <span className="text-[#00df98] cursor-pointer">
              {" "}
              Privacy Policy.
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Newsletter;
