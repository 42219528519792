import React from "react";
import Laptop from "../assets/laptop.jpg";

function Analytics() {
  return (
    <div className="w-full bg-white py-16 ">
      <div className="mx-w-[1240px] mx-auto grid md:grid-cols-2  md:items-center">
        <img className="w-[400px] md:w-[600px] mx-auto" src={Laptop} alt="/" />
        <div className="flex flex-col gap-2 px-4  ">
          <p className="text-[#00df98] text-sm font-bold">
            DATA ANALYTICS DASHBOARD
          </p>
          <h1 className="text-2xl md:text-4xl  text-black font-bold">
            Manage Data Analytics Centrally
          </h1>
          <p>
            Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ut vero
            nemo sit dignissimos atque rem debitis, mollitia numquam quasi
            nesciunt pariatur deserunt porro dicta aut expedita eligendi tempore
            impedit autem?
          </p>
          <button className=" mx-auto p-1 px-4 md:px-10  mt-3 bg-[#00df98] text-white font-medium rounded-sm">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
